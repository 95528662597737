import React, { createContext, useState } from "react";

const HomepageContext = createContext();

export const HomepageProvider = ({ children }) => {
  const [value, setValue] = useState(false);

  return (
    <HomepageContext.Provider value={{ value, setValue }}>
      {children}
    </HomepageContext.Provider>
  );
};

export default HomepageContext;
