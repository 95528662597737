import React, { useState, useEffect, useRef } from "react";
import kolGif from "../img/kol.avifs"; // GIF dosyasının yolu
import kolStatic from "../img/kol-static.avif";
import kolBg from "../img/kolbg.avif";
import kolMobileStatic from "../img/kol-mobile.avif";
import kolMobileGif from "../img/kol-mobile.avifs";
import arrow from "../img/arrow-narrow-right-svgrepo-com.svg";
import { Link as ScrollLink } from "react-scroll";
import mobileBg from "../img/mobilebg.avif";

function Kol() {
  const [isGifVisible, setGifVisible] = useState(true); // İlk olarak true yapıldı
  const [isTriggered, setIsTriggered] = useState(false); // Bileşenin bir kere tetiklenip tetiklenmediğini takip etmek için yeni bir durum
  const kolRef = useRef();
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [isBg, setIsBg] = useState(true);

  const isMobile = window.innerWidth <= 768;

  useEffect(() => {
    // İlk yüklemede GIF'i gizlemek için bir zamanlayıcı
    const initialTimer = setTimeout(
      () => {
        setGifVisible(false);
      },
      isMobile ? 3200 : 3200
    );

    return () => clearTimeout(initialTimer);
  }, [isMobile]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !isTriggered) {
            setGifVisible(true);
            setIsOverlayVisible(true);
            setIsTriggered(true); // Bir kere tetiklendikten sonra true yap
            setIsBg(false);
            const timerKol = setTimeout(
              () => {
                setGifVisible(false);
              },
              isMobile ? 3200 : 3200
            );
            return () => clearTimeout(timerKol);
          }
        });
      },
      { threshold: 0.5 } // %30 görünürlük sağlandığında tetikler
    );
    const currentRef = kolRef.current;

    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, [isTriggered, isMobile]);

  return (
    <div
      ref={kolRef}
      id="kol-marketing"
      className="relative w-full mobile:bg-pulpMobileGray mobile:h-auto tablet:h-[50vh] h-screen"
    >
      <img
        src={isMobile ? mobileBg : kolBg}
        alt="Background"
        className="absolute inset-0 w-full h-full object-cover z-30"
        style={{ display: isBg ? "block" : "none" }}
      />
      <div className="container ml-auto mr-auto">
        <img
          src={isMobile ? kolMobileGif : kolGif}
          alt="GIF"
          className={`mobile:relative absolute h-full inset-0 tablet:object-center w-full mobile:h-[40vh] mobile:object-cover mobile:object-left tablet:object-cover object-cover 2xl:object-cover ${
            isGifVisible ? "opacity-100" : "opacity-0"
          } z-20`}
          style={{ display: isGifVisible ? "block" : "none" }}
        />
        <img
          src={isMobile ? kolMobileStatic : kolStatic}
          alt="Static"
          className={`mobile:relative absolute h-full inset-0 tablet:object-center w-full mobile:h-[40vh] mobile:object-cover mobile:object-left tablet:object-cover object-cover 2xl:object-cover ${
            isGifVisible ? "opacity-0" : "opacity-100"
          } z-20`}
          style={{ display: isGifVisible ? "none" : "block" }}
        />
        <div
          className={`mobile:relative absolute mobile:w-5/6 container mobile:h-full md:mr-10 mobile:inset-0 lg:mr-10 2xl:mr-auto mr-auto ml-auto inset-0 flex flex-col items-right justify-center mobile:mt-3 mt-20 p-4 duration-1000 transform ${
            isOverlayVisible ? "opacity-100" : "opacity-0"
          } z-40`}
        >
          <div>
            <h3 className="text-orange-400 mobile:text-right mobile:text-lg tablet:text-lg text-right text-2xl xl:text-2xl 2xl:text-3xl font-semibold">
              works
            </h3>
          </div>
          <div>
            <h2 className="text-white font-semibold mobile:text-right mobile:text-3xl tablet:text-3xl lg:text-3xl xl:text-5xl hd:text-5xl 2xl:text-8xl text-right">
              KOL Marketing
            </h2>
          </div>
          <div className="tablet:mt-5 mobile:mt-5 mt-10 mobile:w-full w-1/3 ml-auto font-medium">
            <p className="text-white mobile:mr-auto mobile:mb-2 mobile:text-xs tablet:text-sm mobile:text-right text-right">
              Maximize the brand's reach with campaigns customized around the
              goals and vision, engaging the target audience.
            </p>
          </div>
          <div className="relative mobile:mt-5 mt-10">
            <ScrollLink to="contact" smooth={true} duration={500}>
              <button className="flex ml-auto mobile:w-full mobile:bg-orange-400 tablet:text-sm tablet:p-2 rounded-full w-auto font-semibold mobile:mt-2 bg-none border-orange-400 border-solid border-2 text-white py-2 text-sm lg:text-base 2xl:text-base px-5 mobile:text-base mobile:p-2 mobile:text-center items-center mobile:justify-center justify-start">
                Schedule a Consultation
                <img
                  className="mobile:hidden"
                  src={arrow}
                  width={"25px"}
                  height={"25px"}
                  alt=""
                />
              </button>
            </ScrollLink>
          </div>
          <div className="flex mobile:scale-75 mobile:ml-auto mobile:mt-10 mobile:w-full tablet:w-1/4 w-1/3 h-24 2xl:h-28 mt-7 ml-auto mobile:justify-center justify-end">
            <div className="border-r-2 mobile:px-5 mobile:h-auto tablet:h-16 pr-5 border-orange-300">
              <h1 className="text-4xl mobile:font-bold mobile:text-left mobile:text-3xl 2xl:text-5xl tablet:text-3xl text-white">
                400+
              </h1>
              <p className="text-base mobile:text-left tablet:text-xs mobile:text-lg mt-1 text-left font-normal text-orange-300">
                Influencers
              </p>
            </div>
            <div className="border-r-2 mobile:px-10 mobile:h-auto tablet:h-16 h-24 2xl:h-28 px-5 text-left border-orange-300">
              <h1 className="text-4xl mobile:font-bold mobile:text-left mobile:text-3xl 2xl:text-5xl tablet:text-3xl text-white">
                140M+
              </h1>
              <p className="text-base mobile:text-left mt-1 text-left mobile:text-lg tablet:text-xs font-normal text-orange-300">
                Followers
              </p>
            </div>
            <div className="border-orange-300 mobile:px-5 mobile:h-auto tablet:h-16 pl-5 h-24 2xl:h-28">
              <h1 className="text-4xl mobile:font-bold mobile:text-left mobile:text-3xl 2xl:text-5xl tablet:text-3xl text-white">
                120+
              </h1>
              <p className="text-base mobile:text-left mt-1 text-left mobile:text-lg tablet:text-xs font-normal text-orange-300">
                Active
                <br />
                Clients
              </p>
            </div>
          </div>
          <div className="hidden mobile:block mt-7 mb-12 w-full h-1 bg-pulpPurple rounded-full mx-auto"></div>
        </div>
      </div>
    </div>
  );
}

export default Kol;
