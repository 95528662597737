// LoadingPage.js
import React from "react";
import { ClipLoader } from "react-spinners";

const LoadingPage = () => {
  return (
    <div style={styles.container}>
      <ClipLoader size={200} color={"#FF8C5F"} loading={true} />
      <p className="text-white text-2xl">Loading...</p>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100vh",
    backgroundColor: "#47177B",
  },
};

export default LoadingPage;
