import React from "react";
import { useEffect } from "react";
import aboutShape from "../img/aboutshape.png";
import aboutMobile from "../img/about-mobile.avif";

import Values from "./Values";
function AboutUsContent() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="bg-pulpMobileGray">
      <img
        src={aboutMobile}
        alt="Static"
        className={`relative pt-20 pb-20 mobile:block sm:hidden inset-0 tablet:object-center w-full h-full mobile:object-contain  tablet:object-cover   object-fill 2xl:object-fill z-30"
            }`}
      />
      <div className="relative w-full h-screen mobile:h-[30vh] mobile:mt-0 bg-pulpMobileGray sm:bg-about-bg sm:bg-cover ">
        <div className="absolute mobile:relative  w-4/6 mobile:w-full  h text-white container text-left 2xl:ml-auto mr-auto ml-auto inset-0 flex flex-col items-right justify-center mt-0 p-4 duration-1000 transform z-40">
          <h1 className="text-6xl mobile:w-full -mt-20">About Us</h1>
          <div className="w-2/3 mobile:w-full mobile:ml-2  mt-12">
            Awarded as the #1 Service Provider in New Media for Web3, with a
            perfect fusion of professional excellence and creative brilliance,
            we set trends and redefine standards. Experience the essence of
            innovation with Pulp Media — where we pump content, creativity, and
            success.
          </div>
        </div>
        <img
          src={aboutShape}
          alt="Static"
          className={`absolute mobile:hidden inset-0 tablet:object-center w-full h-full mobile:object-cover  tablet:object-cover   object-fill 2xl:object-fill z-30"
            }`}
        />
      </div>
      <Values></Values>
    </div>
  );
}

export default AboutUsContent;
