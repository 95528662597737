import React, { useContext, useEffect, useState } from "react";
import Hero from "../components/Hero";
import Sm from "../components/Sm";
import Cm from "../components/Cm";
import Guerrilla from "../components/Guerrilla";
import Kol from "../components/Kol";
import DigitalArt from "../components/DigitalArt";
import Header from "../components/Header";
import HomepageContext from "../context/HeroContext";
import Partners from "../components/Partners";
import Awards from "../components/Awards";
import Footer from "../components/Footer";
import Contact from "../components/Contact";
import { useLocation } from "react-router-dom";
import { scroller } from "react-scroll";
import Loading from "./LoadingPage"; // Loading bileşenini import edin

function Homepage({ isLoading }) {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0); // Sayfanın en üstüne kaydırır
  }, []);

  useEffect(() => {
    if (!isLoading && location.hash) {
      scroller.scrollTo(location.hash.replace("#", ""), {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
      });
    }
  }, [location, isLoading]);

  useEffect(() => {
    if (isLoading) {
      // Sayfa kaydırmasını engellemek için body'se stil ekleyin
      document.body.style.overflow = "hidden";
    } else {
      // Yükleme tamamlandığında body'se stili geri alın
      document.body.style.overflow = "auto";
    }

    // Cleanup function
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isLoading]);

  return (
    <div className="homepage">
      <Hero isLoading={isLoading} />
      {isLoading && (
        <div className="fixed inset-0 flex items-center justify-center bg-white z-50">
          <Loading /> {/* Mevcut loading bileşeninizi kullanın */}
        </div>
      )}
      <Header />
      <Sm />
      <Cm />
      <Guerrilla />
      <Kol />
      <DigitalArt />
      <Contact />
      <Partners />
      <Awards />
      <Footer />
    </div>
  );
}

export default Homepage;
