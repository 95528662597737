import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaBars, FaTimes, FaChevronDown } from "react-icons/fa";
import PulpLogo from "../img/pulp-logo.svg";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
function Header() {
  const [showHeader, setShowHeader] = useState(false);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [servicesOpen, setServicesOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowHeader(true);
    }, 500);

    return () => clearTimeout(timer);
  }, []);

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen);
  };

  const toggleServices = () => {
    setServicesOpen(!servicesOpen);
  };

  const handleScrollLinkClick = (e, target) => {
    e.preventDefault();
    navigate(`/#${target}`, { state: { from: window.location.pathname } });
  };

  return (
    <>
      {/* Desktop Header */}
      <header
        id="header"
        className={`fixed top-5 text-center rounded-full left-0 right-0 tablet:w-11/12 sm:w-5/6 2xl:w-2/3 bg-opacity-75 bg-pulpPurple text-white p-4 z-50 mx-auto transition-opacity duration-1000 ${
          showHeader ? "opacity-100" : "opacity-0"
        } hidden md:block`}
      >
        <div className="flex justify-between items-center text-sm sm:text-lg font-semibold ml-10">
          <div className="w-1/5 mt-2">
            <Link to="/">
              <img src={PulpLogo} width="75" alt="Pulp Logo" />
            </Link>
          </div>
          <div className="relative mt-1 group">
            <span className="cursor-pointer">Services</span>
            <div className="absolute left-1/2 transform -translate-x-1/2 mt-7 w-60 bg-pulpPurple text-white rounded-lg shadow-lg opacity-0 group-hover:opacity-100 group-hover:visible group-focus-within:opacity-100 group-focus-within:visible transition-opacity duration-300">
              <a
                href="/#social-media"
                onClick={(e) => handleScrollLinkClick(e, "social-media")}
                className="block px-4 py-2 text-base cursor-pointer hover:bg-pulpPurple rounded-lg"
              >
                Social Media Management
              </a>
              <a
                href="/#community-management"
                onClick={(e) =>
                  handleScrollLinkClick(e, "community-management")
                }
                className="block px-4 py-2 text-base cursor-pointer hover:bg-pulpPurple rounded-lg"
              >
                Community Management
              </a>
              <a
                href="/#guerrilla"
                onClick={(e) => handleScrollLinkClick(e, "guerrilla")}
                className="block px-4 py-2 text-base cursor-pointer hover:bg-pulpPurple rounded-lg"
              >
                Guerrilla Marketing
              </a>
              <a
                href="/#kol-marketing"
                onClick={(e) => handleScrollLinkClick(e, "kol-marketing")}
                className="block px-4 py-2 text-base cursor-pointer hover:bg-pulpPurple rounded-lg"
              >
                KOL Marketing
              </a>
              <a
                href="/#digital-art"
                onClick={(e) => handleScrollLinkClick(e, "digital-art")}
                className="block px-4 py-2 text-base cursor-pointer hover:bg-pulpPurple rounded-lg"
              >
                Creative Digital Art
              </a>
            </div>
          </div>
          <div className="mt-1">
            <Link to="/about-us">About</Link>
          </div>
          <div className="mt-1">
            <Link to="/team">Team</Link>
          </div>
          <div>
            <ScrollLink to="contact" smooth={true} duration={500} className="">
              <button className="bg-white px-8 py-3 rounded-full text-black">
                Contact Us
              </button>
            </ScrollLink>
          </div>
        </div>
      </header>

      {/* Mobile Header */}
      <header
        id="header2"
        className={`fixed top-0 left-0 right-0 rounded-full w-11/12 ml-auto mr-auto mt-3 bg-pulpPurple text-white p-4 z-50 md:hidden`}
      >
        <div className="flex justify-between items-center">
          <div>
            <Link to="/">
              <img src={PulpLogo} width="50" alt="Pulp Logo" />
            </Link>
          </div>
          <button
            onClick={toggleDrawer}
            className="text-white focus:outline-none"
          >
            {drawerOpen ? <FaTimes size={28} /> : <FaBars size={28} />}
          </button>
        </div>
        <div
          className={`fixed inset-0 bg-black bg-opacity-50 z-40 transition-opacity duration-300 ${
            drawerOpen ? "opacity-100 block" : "opacity-0 hidden"
          }`}
          onClick={toggleDrawer}
        ></div>
        <div
          className={`fixed inset-y-0 left-0 bg-pulpPurple text-white w-64 z-50 transform ${
            drawerOpen ? "translate-x-0" : "-translate-x-full"
          } transition-transform duration-300`}
        >
          <div className="p-4">
            <div className="flex items-center justify-between">
              <div>
                <Link to="/">
                  <img src={PulpLogo} width="50" alt="Pulp Logo" />
                </Link>
              </div>
              <button
                onClick={toggleDrawer}
                className="text-white focus:outline-none"
              >
                <FaTimes size={28} />
              </button>
            </div>
            <div className="mt-8 text-center">
              <div
                className="flex justify-center items-center cursor-pointer"
                onClick={toggleServices}
              >
                Services <FaChevronDown className="ml-1" />
              </div>
              <div
                className={`mt-2 transition-opacity duration-300 ${
                  servicesOpen ? "opacity-100 block" : "opacity-0 hidden"
                }`}
              >
                <a
                  href="/#social-media"
                  onClick={(e) => handleScrollLinkClick(e, "social-media")}
                  className="block px-4 py-2 text-base cursor-pointer hover:bg-pulpPurple rounded-lg"
                >
                  Social Media Management
                </a>
                <a
                  href="/#community-management"
                  onClick={(e) =>
                    handleScrollLinkClick(e, "community-management")
                  }
                  className="block px-4 py-2 text-base cursor-pointer hover:bg-pulpPurple rounded-lg"
                >
                  Community Management
                </a>
                <a
                  href="/#guerrilla"
                  onClick={(e) => handleScrollLinkClick(e, "guerrilla")}
                  className="block px-4 py-2 text-base cursor-pointer hover:bg-pulpPurple rounded-lg"
                >
                  Guerrilla Marketing
                </a>
                <a
                  href="/#kol-marketing"
                  onClick={(e) => handleScrollLinkClick(e, "kol-marketing")}
                  className="block px-4 py-2 text-base cursor-pointer hover:bg-pulpPurple rounded-lg"
                >
                  KOL Marketing
                </a>
                <a
                  href="/#digital-art"
                  onClick={(e) => handleScrollLinkClick(e, "digital-art")}
                  className="block px-4 py-2 text-base cursor-pointer hover:bg-pulpPurple rounded-lg"
                >
                  Creative Digital Art
                </a>
              </div>
              <div
                className="block px-4 py-2 text-base cursor-pointer hover:bg-pulpPurple rounded-lg"
                onClick={toggleDrawer}
              >
                <Link to="/about-us">About</Link>
              </div>
              <div
                className="block px-4 py-2 text-base cursor-pointer hover:bg-pulpPurple rounded-lg"
                onClick={toggleDrawer}
              >
                <Link to="/team">Team</Link>
              </div>
              <ScrollLink
                to="contact"
                smooth={true}
                duration={500}
                className="block mt-4 px-8 py-3 bg-white text-black rounded-full"
              >
                Contact Us
              </ScrollLink>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}

export default Header;
