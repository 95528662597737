import React from "react";
import diamond from "../img/diamond.svg";
import adidas from "../img/adidas.svg";
import gmk from "../img/gmk.svg";

function Awards() {
  return (
    <div className="bg-pulpgrayBg mobile:bg-pulpMobileGray">
      <div className="container pt-10 pulp-o w-5/6 hd:w-5/6 2xl:w-full ml-auto mr-auto lg:h-auto 2xl:h-auto pb-10">
        <div className="text-2xl border-b-2 border-b-orange-300 text-left">
          <h2 className="text-orange-400 mb-2">AWARDS</h2>
        </div>
        <div className="grid mt-5 grid-rows-3 gap-4 w-full ml-auto mr-auto">
          <div className="rounded-full flex mt-2 tablet:w-full mobile:w-full w-3/5 h-12 mr-auto mobile:bg-pulpOrange bg-pulpBlack p-1 xl:p-2">
            <img
              className=" ml-2"
              src={diamond}
              width="40px"
              alt="Diamond Award"
            />
            <h2 className="text-white mobile:text-xs mobile:mt-3 mobile:ml-2 text-xl mt-1 ml-5">
              2021 International Crystal Diamond Awards
            </h2>
          </div>
          <div className="rounded-full flex mt-2 tablet:w-full mobile:w-full w-3/5 h-12 mr-auto mobile:bg-pulpOrange bg-pulpBlack p-1 xl:p-2">
            <img
              className=" ml-2"
              src={adidas}
              width="40px"
              alt="Adidas Award"
            />
            <h2 className="text-white mobile:text-xs mobile:mt-3 mobile:ml-2 text-xl mt-1 ml-5">
              2022 Adidas Design Award
            </h2>
          </div>
          <div className="rounded-full flex mt-2 tablet:w-full mobile:w-full w-3/5 h-12 mr-auto mobile:bg-pulpOrange bg-pulpBlack p-1 xl:p-2">
            <img className=" ml-2" src={gmk} width="40px" alt="GMK Award" />
            <h2 className="text-white mobile:text-xs mobile:mt-3 mobile:ml-2 text-xl mt-1 ml-5">
              2021 GMK Design Award
            </h2>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Awards;
