import React from "react";
import logo from "../img/pulp-logo.svg";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";

function Footer() {
  const navigate = useNavigate();
  const handleScrollLinkClick = (e, target) => {
    e.preventDefault();
    navigate(`/#${target}`, { state: { from: window.location.pathname } });
  };
  return (
    <div className="bg-pulpgrayBg pb-10">
      <div className="container pt-5 pb-5 pulp-o w-5/6 hd:w-5/6 2xl:w-full ml-auto mr-auto border-b-2 border-b-orange-300">
        <div className="grid gap-3   mobile:grid-cols-2 grid-cols-2 md:grid-cols-4">
          <div className="p-6 grid gap-4 text-center mobile:text-center mobile:text-base tablet:text-base text-xl text-white">
            <Link to="/">
              <img
                src={logo}
                alt="Pulp Logo"
                className="mobile:w-24 tablet:w-24 tablet:mr-auto  w-32 ml-auto mr-auto pb-2 h-auto"
              />
            </Link>
            <div>#1 Service Provider in digital marketing</div>
            <div>hello@pulpmedia.io</div>
            <div className="text-pulpFooterGray text-sm">
              © 2024 Pulp Media. <br /> All rights reserved.
            </div>
          </div>
          <div className="p-6 text-xl mobile:text-center text-white">
            <div className="text-pulpFooterGray text-sm">ABOUT PULP MEDIA</div>
            <Link to="/about-us">
              <div className="text-lg mt-2">Pulp</div>
            </Link>
            <Link to="/team">
              <div className="text-lg mt-2">Team</div>
            </Link>
          </div>
          <div className="p-6 text-xl mobile:text-center text-white">
            <div className="text-pulpFooterGray text-sm">TOUCH WITH US</div>
            <a href="https://x.com/pumpwithpulp">
              <div className="text-lg mt-2">Twitter</div>
            </a>
            <a href="https://www.linkedin.com/company/pumpwithpulp">
              <div className="text-lg mt-2">Linkedin</div>
            </a>
            <a href="https://t.me/PulpArmy">
              <div className="text-lg mt-2">Telegram</div>
            </a>
          </div>
          <div className="p-6 text-xl mobile:text-center text-white">
            <div className="text-pulpFooterGray text-sm">SUPPORT</div>

            <Link to="/team">
              <div className="text-lg mt-2">Verification</div>
            </Link>
            <ScrollLink to="contact" smooth={true} duration={500} className="">
              <div className="text-lg mt-2 cursor-pointer">Contact Us</div>
            </ScrollLink>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
