import React from "react";
import innovation from "../img/innovation2.png";
import relentless from "../img/relentless.png";
import customercen from "../img/customercen.png";

function Values() {
  return (
    <div className="bgvalues-bg min-h-screen">
      <div className="relative flex container ml-auto mr-auto w-full h-auto md:h-auto ">
        <div className="h text-white container text-left md:ml-5 lg:ml-5 2xl:ml-auto mr-auto ml-auto inset-0 flex flex-col items-center mt-0 p-4 duration-1000 transform">
          <div className="w-4/5 mt-10 mb-10 ">
            <h1 className="mb-10 text-left text-5xl">Our Values</h1>
          </div>
          <div className="flex flex-col w-4/5 md:flex-row tablet:grid tablet:grid-cols-2 tablet:w-full mobile:flex-col ml-auto mr-auto gap-10 justify-center">
            <div className="bg-pulpgrayBg shadow-2xl rounded-3xl h-auto md:h-[500px] w-full py-5 mb-10 md:mb-0">
              <div className="w-full mt-2 flex justify-center">
                <img src={innovation} width={"150px"} size="25" alt="" />
              </div>
              <div>
                <h1 className="text-3xl md:text-5xl mt-5 text-center">
                  Innovation & Creativity:
                </h1>
              </div>
              <div className="w-11/12 ml-auto mr-auto p-5 mt-5">
                <p>
                  Pushing the boundaries of what's possible in new media with
                  cutting-edge ideas and solutions. The top-notch digital art
                  department sets the industry standard for creativity and
                  quality.
                </p>
              </div>
            </div>
            <div className="bg-pulpgrayBg shadow-2xl rounded-3xl h-auto md:h-[500px] w-full py-5 mb-10 md:mb-0">
              <div className="w-full mt-2 flex justify-center">
                <img src={customercen} width={"125px"} size="25" alt="" />
              </div>
              <div className="flex justify-center w-11/12 ml-auto mr-auto">
                <h1 className="text-3xl md:text-5xl text-center">
                  Customer- <br /> Centric Excellence:
                </h1>
              </div>
              <div className="w-11/12 ml-auto mr-auto p-5 mt-5">
                <p>
                  Strategies and solutions designed to elevate client success.
                  Delivering unmatched satisfaction through personalized
                  attention and tailored services.
                </p>
              </div>
            </div>
            <div className="bg-pulpgrayBg shadow-2xl tablet:hidden rounded-3xl h-auto md:h-[500px] w-full py-5 tablet:justify-self-center self-center">
              <div className="w-full mt-2 flex justify-center">
                <img src={relentless} width={"150px"} size="25" alt="" />
              </div>
              <div>
                <h1 className="text-3xl md:text-5xl mt-5 text-center">
                  Relentless <br /> Adaption:
                </h1>
              </div>
              <div className="w-11/12 ml-auto mr-auto p-5 mt-5">
                <p>
                  Excellence achieved through continuous R&D, adapting to
                  evolving platform algorithms. Transforming these changes into
                  strategic advantages for superior outcomes.
                </p>
              </div>
            </div>
          </div>
          <div className="bg-pulpgrayBg w-1/2 shadow-2xl mt-10 mb-10 hidden tablet:block rounded-3xl h-auto md:h-[500px] py-5 tablet:justify-self-center self-center">
            <div className="w-full mt-2 flex justify-center">
              <img src={relentless} width={"150px"} size="25" alt="" />
            </div>
            <div>
              <h1 className="text-3xl md:text-5xl mt-5 text-center">
                Relentless <br /> Adaption:
              </h1>
            </div>
            <div className="w-11/12 ml-auto mr-auto p-5 mt-5">
              <p>
                Excellence achieved through continuous R&D, adapting to evolving
                platform algorithms. Transforming these changes into strategic
                advantages for superior outcomes.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Values;
