import React from "react";
import Header from "../components/Header";
import Footer from "../components/Footer";
import AboutUsContent from "../components/AboutUsContent";
import Partners from "../components/Partners";
import Awards from "../components/Awards";
import Contact from "../components/Contact";

function Aboutus() {
  return (
    <div>
      <Header></Header>
      <AboutUsContent></AboutUsContent>
      <Contact></Contact>
      <Partners></Partners>
      <Awards></Awards>
      <Footer></Footer>
    </div>
  );
}

export default Aboutus;
