import React, { useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import Homepage from "./pages/Homepage";
import Aboutus from "./pages/Aboutus";
import Team from "./pages/Team";
import { HomepageProvider } from "./context/HeroContext";
import LoadingPage from "./pages/LoadingPage";
import NotFound from "./pages/NotFound";
function App() {
  const [imagesLoaded, setImagesLoaded] = useState(false);

  useEffect(() => {
    const imgElements = document.querySelectorAll("img");
    let loadedImages = 0;

    const checkImagesLoaded = () => {
      loadedImages += 1;
      if (loadedImages === imgElements.length) {
        setImagesLoaded(true);
      }
    };

    imgElements.forEach((img) => {
      if (img.complete) {
        checkImagesLoaded();
      } else {
        img.onload = checkImagesLoaded;
        img.onerror = checkImagesLoaded; // hata durumunda da çağrılır
      }
    });

    if (imgElements.length === 0) {
      setImagesLoaded(true);
    }
  }, []);

  if (!imagesLoaded) {
    return <LoadingPage />;
  }

  return (
    <div className="App">
      <HomepageProvider>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/about-us" element={<Aboutus />} />
          <Route path="/team" element={<Team />} />
          {/* <Route path="*" element={<NotFound />} /> */}
        </Routes>
      </HomepageProvider>
    </div>
  );
}

export default App;
