import React from "react";
import Slider from "react-slick";
import partnersData from "../api/partners.json";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function Partners() {
  const partners = partnersData.partners;

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    autoplay: true,
    autoplaySpeed: 2000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
          autoplay: true,
          autoplaySpeed: 2000,
        },
      },
    ],
  };

  return (
    <div className="bg-pulpgrayBg mobile:bg-pulpMobileGray">
      <div className="container pt-10 pulp-o w-5/6 hd:w-5/6 2xl:w-full mx-auto lg:h-auto h-auto">
        <div className="text-2xl border-b-2 border-b-orange-300 text-left">
          <h2 className="text-orange-400 mb-4">PARTNERS</h2>
        </div>
        <div className="mt-5 mx-auto overflow-hidden block md:hidden">
          <Slider {...settings}>
            {partners.map((partner, index) => (
              <div key={index} className="p-2 mobile:p-1">
                <a
                  href={partner.link}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div className="flex justify-center items-center rounded-full bg-pulpBlack mobile:p-3 p-4 mx-auto">
                    <img
                      src={`img/partners/${partner.image}`}
                      alt={partner.name} // name özelliğini alt text olarak kullanıyoruz
                      className="w-24 h-auto"
                    />
                  </div>
                </a>
              </div>
            ))}
            <div className="flex justify-center items-center rounded-full text-white mobile:p-3  bg-pulpBlack p-1 mx-auto">
              <h2 className="text-sm">500+</h2>
            </div>
          </Slider>
        </div>
        <div className="hidden md:grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-6 2xl:grid-cols-7 gap-2 mt-5 mx-auto">
          {partners.map((partner, index) => (
            <a href={partner.link} target="_blank" rel="noopener noreferrer">
              <div
                key={index}
                className="flex justify-center items-center rounded-full mt-2 w-40 h-12 bg-pulpBlack p-1 mx-auto"
              >
                <img
                  src={`img/partners/${partner.image}`}
                  alt={partner.name}
                  className="w-24 h-auto"
                />
              </div>
            </a>
          ))}
          <div className="flex justify-center items-center rounded-full mt-2 w-40 h-12 text-white bg-pulpBlack p-1 mx-auto">
            <h2 className="text-sm">500+</h2>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Partners;
