import React, { useState, useEffect, useContext } from "react";
import heroGif from "../img/hero3.avifs"; // GIF dosyasının yolu
import heroStatic from "../img/hero-static2.avif";
import heroArtWork from "../img/artwork2.avif";
import HomepageContext from "../context/HeroContext";
import heroReverse from "../img/hero-reverse4.avifs";
import { useNavigate } from "react-router-dom";
import { scroller } from "react-scroll";
import LoadingPage from "../pages/LoadingPage";

function Hero({ isLoading }) {
  const { value, setValue } = useContext(HomepageContext);
  const [showStatic, setShowStatic] = useState(false);
  const [showArtwork, setShowArtwork] = useState(false);
  const [showGif, setShowGif] = useState(false);
  const [showOverlay, setShowOverlay] = useState(false);
  const [reverse, setReverse] = useState(false);
  const [loading, setLoading] = useState(true); // Yükleme durumunu izleyen state
  const [additionalImagesLoaded, setAdditionalImagesLoaded] = useState(false); // Ek resimlerin yüklenme durumu
  const navigate = useNavigate();

  // Preload the initial images using Image constructor
  useEffect(() => {
    const sessionIsload = localStorage.getItem("isLoad");
    console.log("sessionIsload:", sessionIsload);

    if (sessionIsload === null || sessionIsload === "null") {
      const loadImage = (src) => {
        return new Promise((resolve, reject) => {
          document.getElementById("header").style.opacity = "0";
          document.getElementById("header2").style.opacity = "0";
          const img = new Image();
          img.src = src;
          img.onload = resolve;
          img.onerror = reject;
        });
      };

      const images = Array.from(document.querySelectorAll("img")).map(
        (img) => img.src
      );
      loadImage(heroGif);
      Promise.all(images.map((src) => loadImage(src)))
        .then(() => setLoading(false))
        .catch((err) => {
          console.error("Failed to load images", err);
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, []);

  // Yükleme tamamlandıktan sonra diğer resimleri yükle
  useEffect(() => {
    const sessionIsload = localStorage.getItem("isLoad");
    if (!loading && (sessionIsload === null || sessionIsload === "null")) {
      const loadImage = (src) => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          img.src = src;
          img.onload = resolve;
          img.onerror = reject;
        });
      };

      Promise.all([loadImage(heroArtWork), loadImage(heroReverse)])
        .then(() => setAdditionalImagesLoaded(true))
        .catch((err) => {
          console.error("Failed to load additional images", err);
        });
    }
  }, [loading]);

  useEffect(() => {
    const sessionIsload = localStorage.getItem("isLoad");
    console.log("sessionIsload:", sessionIsload);
    console.log("loading:", loading);
    document.body.style.overflowY = "hidden";
    document.getElementById("header").style.opacity = "0";
    document.getElementById("header2").style.opacity = "0";

    if (
      sessionIsload === null ||
      (sessionIsload === "null") & (isLoading === true) & (loading === false)
    ) {
      const timer = setTimeout(() => {
        setShowGif(true);
        setShowStatic(true);
        setShowOverlay(true);
      }, 2500);

      return () => clearTimeout(timer);
    } else if (sessionIsload === "true") {
      setShowStatic(false);
      setShowOverlay(false);
      setShowGif(true);
      setValue(true);
      setShowArtwork(true);
      document.body.style.overflowY = "scroll";
      document.getElementById("header").style.opacity = "1";
      document.getElementById("header2").style.opacity = "1";
    }
  }, [setValue, isLoading, loading]);

  const changeValue = () => {
    setReverse(true);
    setShowStatic(false);
    setShowOverlay(false);
    setShowArtwork(true);
    const timer2 = setTimeout(() => {
      setValue(true);
      setReverse(false);
      document.body.style.overflowY = "scroll";
      document.getElementById("header").style.opacity = "1";
      document.getElementById("header2").style.opacity = "1";
    }, 2500);

    return () => clearTimeout(timer2);
  };

  useEffect(() => {
    const { hash, state } = window.location;

    if (hash) {
      scroller.scrollTo(hash.replace("#", ""), {
        duration: 800,
        delay: 0,
        smooth: "easeInOutQuart",
      });
    }

    if (state && state.from) {
      console.log(`Navigated from ${state.from}`);
      // Başka bir sayfadan geldiyseniz yapılacak işlemler
      setReverse(false);
      setShowStatic(false);
      setShowOverlay(false);
      setShowArtwork(true);
    }
  }, [navigate]);

  if (loading) {
    return <LoadingPage />; // Yüklenme sırasında gösterilecek preloader
  }

  return (
    <div
      className={`relative w-full h-screen ${
        value ? "mobile:h-[100vh]" : "mobile:h-[100vh]"
      }  mobile:h-[100vh] `}
    >
      <img
        src={heroGif}
        alt="Hero"
        className={`absolute w-full h-full object-cover ${
          showGif ? "hidden" : "block"
        }`}
      />
      <img
        src={heroStatic}
        alt="Hero Static"
        className={`relative w-full h-full object-cover ${
          showStatic ? "block" : "hidden"
        }`}
      />
      <img
        src={heroReverse}
        alt="hero reverse"
        className={`absolute w-full h-[100vh] object-cover ${
          reverse ? "block" : "hidden"
        }`}
      />
      <img
        src={heroArtWork}
        alt="Hero Static"
        className={`w-full h-full object-cover ${
          showArtwork ? "block" : "hidden"
        }`}
      />
      <div
        className={`absolute inset-0 flex flex-col items-center justify-center p-4 transition-opacity duration-1000 ${
          showOverlay ? "opacity-100 block" : "opacity-0 hidden"
        }`}
      >
        <div
          className={`transition-opacity duration-1000 delay-100 ${
            showOverlay ? "opacity-100" : "opacity-0"
          }`}
        >
          <h3 className="text-white font-semibold text-5xl 2xl:text-8xl text-center">
            we pump
          </h3>
        </div>
        <div
          className={`mt-2 2xl:mt-10 mobile:w-3/4 mobile:mt-10 w-1/3 font-semibold transition-opacity duration-1000 delay-200 ${
            showOverlay ? "opacity-100" : "opacity-0"
          }`}
        >
          <p className="text-white font-medium text-sm lg:text-base text-center">
            <h1>Awarded as the #1 Service Provider in New Media for Web3</h1>
          </p>
        </div>
        <div
          className={`transition-opacity duration-1000 delay-300 ${
            showOverlay ? "opacity-100" : "opacity-0"
          }`}
        >
          <button
            className="rounded-full font-semibold mt-10 bg-white py-2 text-lg px-5"
            onClick={() => changeValue()}
          >
            Get Started
          </button>
        </div>
      </div>
    </div>
  );
}

export default Hero;
