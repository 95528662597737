import React, { useEffect, useRef, useState } from "react";
import innovation from "../img/innovation2.png";
import teamData from "../api/team.json";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
function TeamVerification() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const nameInputRef = useRef();
  const [isUserValid, setIsUserValid] = useState(null);

  const teamVerifate = (e) => {
    e.preventDefault();
    const nameValue = nameInputRef.current.value;
    console.log(nameValue);

    const teamMembers = teamData.teamMembers;

    let found = false;
    teamMembers.forEach((user) => {
      if (user === nameValue) {
        setIsUserValid(true);
        toast.success("User is Valid");
        found = true;
      }
    });

    if (!found) {
      setIsUserValid(false);
      toast.error("User is not found");
    }
  };

  return (
    <div className="relative w-full mobile:h-[65vh] h-screen flex items-center mobile:p-4 justify-center">
      <div className="container mx-auto flex items-center justify-center">
        <div className="bg-white mobile:h-auto  h-[50vh] mobile:w-full w-3/4 rounded-3xl flex flex-col justify-between">
          <div className="h-[75%] flex mobile:px-0 justify-between items-center w-full p-8">
            <div>
              <div className="text-5xl mobile:ml-4 mobile:text-3xl text-left ml-16">
                Team Verification:
              </div>
              <div className="text-3xl mobile:ml-4 mobile:tex mobile:text-xl ml-16 mt-2 text-left">
                Check Telegram ID to make sure that they are legit
              </div>
            </div>
            <div className="mobile:-mt-20 mobile:mr-5 -mt-44 mr-8">
              <img width={"100px"} src={innovation} alt="" />
            </div>
          </div>
          <div className="bg-pulpgrayBg mobile:p-2 w-full rounded-b-2xl h-[25%] flex items-center justify-center">
            <div className="max-w-5xl w-full bg-opacity-75 p-4 rounded-lg">
              <form
                className="flex justify-between mobile:gap-2 gap-12"
                onSubmit={teamVerifate}
              >
                <input
                  type="text"
                  className={`p-4 mobile:w-full mobile:text-xs w-4/6 rounded-full border-2 ${
                    isUserValid === null
                      ? "border-gray-300"
                      : isUserValid
                      ? "border-green-500"
                      : "border-red-500"
                  } focus:outline-none placeholder-black text-lg`}
                  placeholder="Person Tg Account Here"
                  name="name"
                  ref={nameInputRef}
                />

                <button
                  type="submit"
                  className="rounded-full mobile:text-xs mobile:w-3/6 w-2/6 text-xl bg-orange-400 p-2 text-white hover:bg-orange-500"
                >
                  Check The Person
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default TeamVerification;
